import classNames from "classnames";
import { inject } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";

import { LogoImg } from "../../../core/chrome/StationLogo.component";
import "../../../core/chrome/StationLogo.style.scss";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { COAST_MOBILE_LOGO } from "@inferno/renderer-shared-core";

interface CoastStationLogoProps {
  isMobile: boolean;
  store?: Store;
}

export const CoastStationLogo = inject("store")(({ store, isMobile }: CoastStationLogoProps) => {
  if (!store) {
    return null;
  }

  const { site, env } = store;

  if (!site.sections.design?.station_logo?.id || !site.sections.general) {
    return null;
  }

  const logoUrl = isMobile ? COAST_MOBILE_LOGO : formatImage(site.sections.design?.station_logo?.id, env.IMAGE_HOST);
  const height = isMobile ? 52 : 130;
  const width = isMobile ? 200 : 125;

  const alt = `${site.sections.general?.name} - ${site.sections.general?.positioner}`;

  const className = classNames("component-station-logo", { "mobile-logo": isMobile });

  return (
    <figure className={className}>
      <LogoImg imgUrl={logoUrl} stationUrl="/" alt={alt} width={width} height={height} />
    </figure>
  );
});
