import { Fragment, useState, useEffect } from "react";
import { Modal } from "../../../../core/ui";
import { Button, ButtonKind } from "../../../../core/ui/";
import Heading from "../../../../core/ui/Heading.component";
import styles from "./CoastNewsLetterModal.module.scss";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import Overlay from "../../../../core/ui/Overlay.component";

import { inject } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";
import { overflowUnset } from "@inferno/renderer-shared-core";

interface CoastNewsLetterModalProps {
  store?: Store;
}

export const CoastNewsLetterModal = inject("store")(({ store }: CoastNewsLetterModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const pageName = store?.page.currentPage?.name;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const modalStatus = localStorage.getItem("modalStatus");
      if (pageName === "home" && !modalStatus) {
        setShowModal(true);
        localStorage.setItem("modalStatus", JSON.stringify("true"));
      }
    }
  }, [pageName]);

  if (!showModal) return null;

  const handleDismiss = () => {
    setShowModal(false);
    overflowUnset();
  };

  const handleSignup = () => {
    history.push("/pages/coastzone/");
    handleDismiss();
  };

  return createPortal(
    <Fragment>
      <Overlay open={showModal} handleClick={handleDismiss} />
      <div className={styles.modalContainer}>
        <Modal open={showModal} callback={handleDismiss} addClass={styles.componentCoastNewsletterModal}>
          <div className={styles.modalContent}>
            <Heading level={3} className={styles.heading}>
              Like Bizarre History?
            </Heading>
            <hr className={styles.divider} />
            <p className={styles.text}>
              Find out what happened on this date in the "Today in Strangeness" feature in the CoastZone newsletter!
            </p>
            <div className={styles.footer}>
              <Button kind={ButtonKind.PRIMARY} className={styles.coastButtonText} click={handleSignup}>
                Sign me up!
              </Button>
              <Button kind={ButtonKind.CUSTOMIZED} className={styles.noThanksButton} click={handleDismiss}>
                No,thanks
              </Button>
              <img
                src="https://i.iheart.com/v3/re/assets.coast2coast/62058d8b55d14e9879dace94"
                height="43"
                width="50"
                alt="logo-stacked"
              />
            </div>
          </div>
        </Modal>
      </div>
    </Fragment>,
    document.body,
  );
});
