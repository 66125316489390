import { inject, observer } from "mobx-react";
import { Component } from "react";
import type { Store } from "@inferno/renderer-shared-core";

import { ClickSection } from "@inferno/renderer-shared-core";
import { Container } from "../../../core/ui";
import "./CoastHeader.style.scss";
import { CoastHeaderSearch } from "./CoastHeaderSearch.component";
import { CoastNavigation } from "./CoastNavigation.component";
import { CoastStationLogo } from "./CoastStationLogo.component";

export interface CoastHeaderProps {
  store?: Store;
}

@inject("store")
@observer
export class CoastHeader extends Component<CoastHeaderProps> {
  public render() {
    const { store } = this.props;

    if (!store) {
      return null;
    }

    const {
      site: { sections },
    } = store;

    return (
      <header className="component-site-header">
        <Container className="main-header-items">
          <ClickSection.Provider value="nav">
            <CoastNavigation menus={sections.navigation?.menu} sections={sections} />
          </ClickSection.Provider>

          <ClickSection.Provider value="header">
            <CoastStationLogo isMobile={true} />
            <CoastStationLogo isMobile={false} />

            <section className="search-user-container">
              <CoastHeaderSearch location="user" />
            </section>

            <img
              src="https://i.iheart.com/v3/re/new_assets/89a696d6-a027-4da9-8091-2dfefda74697?ops=gravity(%22center%22),fit(188,135),quality(80)"
              className="george-noory"
              height="135"
              width="188"
              alt=""
            />
          </ClickSection.Provider>
        </Container>
      </header>
    );
  }
}
