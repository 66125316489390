import loadable from "@loadable/component";
import "./CoastHeaderUser.style.scss";

const CoastUserProfile = loadable(() => import("./CoastUserProfile.component"), {
  ssr: false,
});

export const CoastHeaderUser = () => (
  <div className="header-user">
    <CoastUserProfile />
  </div>
);
