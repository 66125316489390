import loadable from "@loadable/component";
import { inject, observer } from "mobx-react";

import { SiteThemeProps } from "@inferno/renderer-shared-core";
import { CoastFooter } from "@inferno/renderer-shared-ui";
import { Routes } from "../Routes";
import { TemplateComponent } from "../TemplateComponent.component";
import { CoastHeader } from "./chrome/CoastHeader.component";
import CoastHeaderAd from "./chrome/CoastHeaderAd.component";
import { CoastNewsLetterModal } from "./components/user/CoastNewsLetterModal.component";
import "./index.style.scss";

const LegalNoticeLoader = loadable(() => import("../../core/page-blocks/legalnotice/LegalNoticeLoader.component"), {
  ssr: false,
});
const LeavingSiteLoader = loadable(() => import("../../core/components/leaving-site/LeavingSiteLoader.component"), {
  ssr: false,
});
const CoastUserModal = loadable(() => import("./components/user/CoastUserModal.component"), {
  ssr: false,
});

export const CoastSiteTheme = inject("store")(
  observer(({ store, siteTheme }: SiteThemeProps) => {
    if (!store) {
      return null;
    }

    return (
      <TemplateComponent>
        {store.page.tagsSet.has("display-hints/omit-header") ? null : <CoastHeader />}
        <CoastHeaderAd />
        <Routes siteTheme={siteTheme} />
        <LeavingSiteLoader />
        <CoastFooter />
        <LegalNoticeLoader />
        <CoastUserModal />
        <CoastNewsLetterModal />
      </TemplateComponent>
    );
  }),
);

export default CoastSiteTheme;
