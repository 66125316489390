import { inject, observer } from "mobx-react";
import { Component } from "react";
import type { Store } from "@inferno/renderer-shared-core";

import { getPushDown } from "@inferno/renderer-shared-core";
import { Ad } from "../../../core/page-blocks/Ad.component";
import "./CoastHeaderAd.style.scss";

interface CoastHeaderAdProps {
  store?: Store;
}

@inject("store")
@observer
export class CoastHeaderAd extends Component<CoastHeaderAdProps> {
  render() {
    const { store } = this.props;
    if (!store || !store.page.currentPage) {
      return null;
    }

    const adBlock = getPushDown(store.page.currentPage);

    if (!adBlock) {
      return null;
    }

    return (
      <div className="component-header-ad">
        <Ad block={adBlock} isPrimary={false} />
      </div>
    );
  }
}

export default CoastHeaderAd;
