import { slugify } from "@ihr-radioedit/sdk-utils";
import classnames from "classnames";
import { Component } from "react";
import type { Store } from "@inferno/renderer-shared-core";

import { NavigationMenuItem } from "../../../core/chrome/NavigationMenu.component";
import { ExpandIcon } from "../../../core/components/icons/ExpandIcon.component";
import { CoastSitesMenu } from "./CoastNavigation.component";

interface MenuProps {
  menu: CoastSitesMenu;
  mainNavOpen?: boolean;
  forceOpen?: boolean;
  store: Store;
}
interface MenuState {
  open: boolean;
}

export class CoastNavigationMenu extends Component<MenuProps, MenuState> {
  mounted = false;

  constructor(props: MenuProps) {
    super(props);

    this.state = { open: !!props.forceOpen };
  }

  componentDidUpdate() {
    // if the main nav is closed, close the current menu too
    if (this.state.open && !this.props.mainNavOpen) {
      this.closeMenu();
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  closeMenu = () => {
    if (this.mounted) {
      this.setState({ open: false });
    }
  };

  toggleOpen = () => {
    if (this.mounted) {
      if (!this.state.open) {
        const page = this.props.store?.page.currentPage;

        if (page) {
          const pageName = page ? `${this.props.store?.microsite ? "microsite_" : ""}${page.name}` : "";
          let referrer = "";
          if (typeof window !== "undefined") {
            referrer = window.location.href;
          }

          this.props.store?.onAnalyticsAction.dispatch({
            sectionName: "parent_nav",
            pageName,
            context: this.props.menu.label || "",
            action: "click",
            url: "",
            referrer,
          });
        }
      }

      this.setState({ open: !this.state.open });
    }
  };

  render() {
    const { menu, mainNavOpen } = this.props;
    const { open } = this.state;
    const toggleClass = classnames("menu-top-label", "menu-toggler", {
      open: open && mainNavOpen,
    });

    if (menu.children?.length) {
      return (
        <li className={toggleClass}>
          <button
            aria-label={`Toggle Menu for ${menu.label || "menu item"}`}
            className="button-text child-menu-toggler"
            onClick={this.toggleOpen}
            tabIndex={0}
          >
            <span className="menu-label">{menu.label}</span>
            <ExpandIcon />
          </button>

          <ul className="submenu-container">
            {menu.children.map((menuItem, index) =>
              menuItem && menuItem.href && menuItem.label ? (
                <li key={index}>
                  <NavigationMenuItem
                    menu={menuItem}
                    context={menu.label ? `${slugify(menu.label)}_${index}` : undefined}
                  />
                </li>
              ) : null,
            )}
          </ul>
        </li>
      );
    }

    return (
      <li className="menu-top-label">
        <NavigationMenuItem menu={menu} />
      </li>
    );
  }
}
