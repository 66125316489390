import { inject } from "mobx-react";

import { ClickSection, socialConfigAbstract, Store } from "@inferno/renderer-shared-core";
import type { Site } from "@ihr-radioedit/inferno-core";
import { SitesMenu } from "@ihr-radioedit/inferno-webapi";
import { reverseRoute } from "@inferno/renderer-shared-core";

import { SocialIcons } from "../icons/SocialIcons.component";
import "./CoastFooter.style.scss";
import { Container } from "../elements/Container.component";
import MagicLink from "../elements/MagicLink.component";

interface FooterProps {
  store?: Store;
  amp?: boolean;
}

interface ResolvedMenu extends SitesMenu {
  href?: string;
}

const resolveFooterLinks = (menu: ResolvedMenu, site: Site): ResolvedMenu => {
  if (!menu.href && menu.ref) {
    menu.href = menu.type === "page" ? reverseRoute(site, menu.ref) : menu.ref;
  }

  if (menu.children) {
    menu.children = menu.children.map(m => m && resolveFooterLinks(m, site));
  }

  return menu;
};

const resolveFooterItems = (site: Site, localFooter?: SitesMenu[] | null) => {
  if (!localFooter) {
    return null;
  }

  return localFooter.map(menu => resolveFooterLinks(menu, site));
};

export const CoastFooter = inject("store")(({ store, amp }: FooterProps) => {
  if (!store) {
    return null;
  }

  const { site, env } = store;
  const { sections } = site;

  const socialIcons = socialConfigAbstract(sections.social);

  const resolvedMenuItems = resolveFooterItems(site, sections.navigation?.menu_footer);

  const menuItems = resolvedMenuItems
    ? resolvedMenuItems.map((menuItem, index: number) => (
        <li key={index}>
          {menuItem.label === "Do Not Sell or Share My Personal Information" || menuItem.id === "kusX7" ? (
            <a onClick={e => store.oneTrust?.toggleInfoDisplay(e)}>Do Not Sell or Share My Personal Information</a>
          ) : (
            <MagicLink key={index} to={menuItem.href || menuItem.ref || ""} target={menuItem.target || ""}>
              {menuItem.label}
            </MagicLink>
          )}
        </li>
      ))
    : null;

  return (
    <ClickSection.Provider value="footer">
      <footer className="component-coast-footer">
        <Container block={false}>
          <div className="component-social-network-icons">
            <SocialIcons networks={socialIcons} />
          </div>

          <div className="site-ownership">
            Coast to Coast AM -&nbsp;
            <a href="http://www.premierenetworks.com/" target="_blank" rel="noreferrer">
              &copy;{new Date().getFullYear()} Premiere Networks Inc.
            </a>
          </div>

          <div className="footer-row footer-links">
            {sections.navigation?.menu_footer?.length ? (
              <ul>
                {menuItems}
                <li>
                  <MagicLink to={`/adchoices/`}>
                    AdChoices
                    {env.AD_ICON && !amp ? (
                      <>
                        &nbsp;
                        <img data-src={env.AD_ICON} alt="AdChoices Logo" className="lazyload" />
                      </>
                    ) : null}
                  </MagicLink>
                </li>
              </ul>
            ) : null}
          </div>
        </Container>
      </footer>
    </ClickSection.Provider>
  );
});
