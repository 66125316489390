import classnames from "classnames";
import { useState } from "react";
import { CloseIcon } from "../../../core/components/icons/CloseIcon.component";
import { SearchIcon } from "../../../core/components/icons/SearchIcon.component";
import { SearchBox } from "../../../core/ui";
import "./CoastHeaderSearch.style.scss";

export const CoastHeaderSearch = ({ location }: { location?: string }) => {
  const [showSearch, setSearch] = useState(false);
  const labelText = showSearch ? "Close Search" : "Open Search";

  const searchClass = classnames("coast-header-search", {
    open: showSearch,
  });

  return (
    <div className={searchClass}>
      <SearchBox location={`${location ?? "header"}`} />

      <button className="search-button search-toggle" aria-label={labelText} onClick={() => setSearch(!showSearch)}>
        {showSearch ? <CloseIcon /> : <SearchIcon />}
      </button>
    </div>
  );
};
