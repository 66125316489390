import classnames from "classnames";
import * as React from "react";
import ReactDOM from "react-dom";
import { bodyScroll } from "../lib/utilities";
import "./Overlay.style.scss";

export interface OverlayProps {
  open: boolean;
  handleClick?: () => void;
}

interface OverlayState {
  open: boolean;
}

export class Overlay extends React.Component<OverlayProps, OverlayState> {
  mounted = false;

  constructor(props: OverlayProps) {
    super(props);
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.open) {
      this.setState({
        open: true,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    if (this.props.handleClick) {
      this.props.handleClick();
    }
    bodyScroll(true, "overlay");
    if (this.mounted) {
      this.setState({
        open: !this.props.open,
      });
    }
  };

  render() {
    const overlayClass = classnames("component-overlay", {
      open: this.props.open,
    });
    if (this.props.open) {
      bodyScroll(false, "overlay");
    }

    const rootEle = document.getElementById("app");
    // attach to root because document.body causes TypeError in tests
    if (rootEle) {
      return ReactDOM.createPortal(
        <div id="BodyOverlay" onClick={this.handleClick} className={overlayClass} />,
        rootEle,
      );
    }
    return null;
  }
}

export default Overlay;
