import loadable from "@loadable/component";
import classnames from "classnames";
import { inject } from "mobx-react";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { useHistory } from "react-router";

import type { AuthEvent } from "@inferno/renderer-shared-core";
import { HamburgerIcon } from "../../../core/components/icons/HamburgerIcon.component";
import { SocialIcons } from "@inferno/renderer-shared-ui";
import { Site } from "@ihr-radioedit/inferno-core";
import type { SitesMenu as DataMenu } from "@ihr-radioedit/inferno-webapi";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { bodyScroll } from "../../../core/lib/utilities";
import { reverseRoute, resizeWatcher } from "@inferno/renderer-shared-core";
import { socialConfigAbstract, SocialNetworkData } from "@inferno/renderer-shared-core";
import { CloseButton } from "../../../core/ui";
import { CoastHeaderSearch } from "./CoastHeaderSearch.component";
import { CoastHeaderUser } from "./CoastHeaderUser.component";
import "./CoastNavigation.style.scss";
import { CoastNavigationMenu } from "./CoastNavigationMenu.component";
import { CoastStationLogo } from "./CoastStationLogo.component";
import type { Store } from "@inferno/renderer-shared-core";

const CoastOnAirStatusLoader = loadable(() => import("./CoastOnAirStatusLoader.component"), {
  ssr: false,
});

export interface CoastSitesMenu extends DataMenu {
  forceOpen?: boolean;
  href?: string;
  resolved?: boolean;
  context?: string;
  children?: CoastSitesMenu[] | null;
}

interface NavProps {
  menus?: CoastSitesMenu[] | null;
  sections: Webapi.UserConfigFragment;
  store?: Store;
  social?: SocialNetworkData[];
}

interface NavContentProps extends NavProps {
  closeNav: () => void;
  open: boolean;
}

export function resolveMenu(menu: CoastSitesMenu, site?: Site) {
  if (!site || menu.href) {
    return menu;
  }

  if (menu.type === "page") {
    if (menu.ref) {
      menu.href = reverseRoute(site, menu.ref);
    }
  } else if (menu.ref && !menu.href) {
    menu.href = menu.ref;
  }

  if (menu.children) {
    menu.children = menu.children.map(m => resolveMenu(m, site));
  }

  return menu;
}

const NavContent = inject("store")(({ open, menus, store, social, closeNav }: NavContentProps) => {
  if (!store) {
    return null;
  }

  const navClass = classnames({ open });

  return (
    <React.Fragment>
      <nav className={navClass} tabIndex={open ? 0 : -1} aria-label="Primary Site Navigation" id="component-site-nav">
        <section className="nav-inner-wrapper">
          <header className="navbar-header">
            <CoastStationLogo isMobile={true} />
            <CloseButton click={closeNav} label="Close Site Navigation" />
          </header>

          <hr className="divider" />

          <CoastOnAirStatusLoader />

          <hr className="divider" />

          <ul className="menu-container main-nav">
            {menus?.map((menu, i) => (
              <CoastNavigationMenu key={i} mainNavOpen={open} forceOpen={menu.forceOpen} menu={menu} store={store} />
            ))}
          </ul>

          {social ? <SocialIcons networks={social} /> : null}

          <footer className="navbar-footer">
            <CoastHeaderSearch location="navbar" />
            <CoastHeaderUser />
          </footer>
        </section>
      </nav>

      {open ? <div className="scroll-blocker" onClick={closeNav} /> : null}
    </React.Fragment>
  );
});

export const CoastNavigation = inject("store")((props: NavProps) => {
  const history = useHistory();
  const socialIcons = useMemo(
    () => props.social ?? socialConfigAbstract(props.sections.social) ?? [],
    [props.social, props.sections.social],
  );
  const [open, setOpen] = useState(false);
  const resolvedMenus = useMemo(
    () => props.menus?.map(menu => resolveMenu(menu, props.store?.site)),
    [props.store?.site, props.menus],
  );

  const toggleOpen = useCallback(
    (toggledOpen: boolean) => {
      if (toggledOpen) {
        const page = props.store?.page.currentPage;
        if (page) {
          props.store?.onAnalyticsAction.dispatch({
            referrer: typeof window !== "undefined" ? window.location.href : "",
            pageName: page.name,
            sectionName: "nav",
            context: "hamburger_menu",
            action: "click",
            url: "",
          });
        }
      }
      setOpen(toggledOpen);
    },
    [props.store?.onAnalyticsAction, props.store?.page.currentPage],
  );
  const closeNav = useCallback(() => {
    toggleOpen(false);
  }, [toggleOpen]);

  const resizeHandler = useCallback(() => {
    // collapse main nav when switched to the desktop layout
    if (window.innerWidth >= 1060 && open) {
      toggleOpen(false);
    }
  }, [open, toggleOpen]);

  useEffect(() => {
    history.listen(closeNav);
    props.store?.onAuthAction.subscribe((event: AuthEvent) => {
      if (event.action === "pending") {
        closeNav();
      }
    });

    if (typeof window !== "undefined") {
      bodyScroll(!open, "nav");
    }
    resizeWatcher.onWidthChange.subscribe(resizeHandler);
    return resizeWatcher.onWidthChange.unsubscribe(resizeHandler);
  }, [closeNav, open, props.store?.onAuthAction, resizeHandler, history]);

  const toggleNav = useCallback(() => toggleOpen(!open), [open, toggleOpen]);

  return (
    <Fragment>
      <button aria-label="Open Site Navigation" className="nav-toggler" onClick={toggleNav} tabIndex={0}>
        <HamburgerIcon />
      </button>

      <NavContent
        open={open}
        menus={resolvedMenus}
        social={socialIcons}
        closeNav={closeNav}
        sections={props.sections}
      />
    </Fragment>
  );
});
